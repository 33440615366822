<template>
	<v-row class="fill-height mx-0" align="center">
		<v-col>
			<v-row justify="center">
				<v-col cols="auto">
					<v-img :width="200" :src="require('@/assets/connection.svg')" />
				</v-col>
			</v-row>
			<v-row justify="center" dense style="margin-bottom: -16px">
				<v-col cols="auto" class="h1">Oooops.</v-col>
			</v-row>
			<v-row justify="center" dense class="mb-4">
				<v-col cols="auto" class="h4 text-center">{{ $t('single_trad.Error') }}</v-col>
			</v-row>
			<v-row justify="center">
				<v-col cols="auto">
					<v-btn large color="primary" :to="home_page" class="font-weight-bold rounded-lg paragraph" width="160">
						<v-icon class="mr-4">mdi-home</v-icon>
						{{ $t('global.name.home') }}
					</v-btn>
				</v-col>
			</v-row>
		</v-col>
	</v-row>
</template>

<script>
	export default {
		name: "Error",
		computed: {
			dash_url(){
				return this.$store.getters.dashboard_url
			},
			market_url(){
				return this.$store.getters.marketplace_url
			},
			home_page(){
				if(this.dash_url){
					return this.dash_url
				}
				else if(this.market_url){
					return this.market_url
				}
				else {
					return '/auth/login'
				}
			}
		}
	}
</script>